<template lang="pug">
.keysList
    v-card
        v-card-text
            v-spacer
            v-simple-table(fixed-header, light)
                template(v-slot:default)
                    thead
                        tr
                            th Key
                            th Type
                            th content
                            th Created
                            th Deployed
                            th
                    tbody
                        tr(v-for="k in keyList", :key="k.id")
                            td {{ k.id }}
                            td {{ k.keyType }}
                            td {{ k.keyContent }}
                            td {{ k.createdAt | niceDateFromUtc }}
                            td {{ k.deployedAt | niceDateFromUtc }}
                            td
                                v-btn(
                                    v-if="!k.deployedAt",
                                    @click="deployKey(k.id)"
                                ) Deploy
                                v-btn(
                                    v-if="k.deployedAt",
                                    @click="unDeployKey(k.id)"
                                ) UnDeploy
                                v-btn(
                                    v-if="!k.deployedAt",
                                    @click="deleteKey(k.id)"
                                ) Delete

        v-card-actions
            v-menu(offset-y)
                template(v-slot:activator='{on, attrs}')
                    v-btn(v-on='on') Add
                v-list
                    v-list-item(v-for='keyType in keyTypes', :key='keyType', @click='createKey(keyType)')
                        v-list-item-title {{ keyType }}
</template>

<script>
import { DateTime } from "luxon";
export default {
    emits: ["key:refreshed"],
    props: {
        customer: Number,
        refresh: Function,
        keyList: Array,
    },
    data () {
        return {
            theKeys: this.KeyList,
            keyTypes: ["infoscanArchive", "dccsAPI", "mongoAuth"],
            message: "",
        };
    },
    methods: {
        // deploy key
        async deployKey (keyId) {
            const params = { keyId };
            try {
                const { success, error } = (
                    await this.axios.post(
                        "/devopsapi/customers/deployKey",
                        params
                    )
                ).data;
                if (success) {
                    this.$emit("key:refreshed", this.customer);
                    return;
                }
                console.error("api success: deployKey failed", error);
            } catch (err) {
                console.error("api fail: deployKey", err);
            }
        },
        // mark an already deployed key as not deployed
        async unDeployKey (keyId) {
            const params = { keyId };
            try {
                const { success, error } = (
                    await this.axios.post(
                        "/devopsapi/customers/unDeployKey",
                        params
                    )
                ).data;
                if (success) {
                    console.log(`unDeployKey success`);
                    this.$emit("key:refreshed", this.customer);
                    return;
                }
                console.error("api success: undeployKey failed", error);
            } catch (err) {
                console.error("api fail: unDeployKey", err);
            }
        },
        // expires specified key
        async deleteKey (keyId) {
            try {
                const params = { keyId };
                const { success, error } = (
                    await this.axios.post(
                        "/devopsapi/customers/deleteKey",
                        params
                    )
                ).data;
                if (error) {
                    this.message = `Deletion Failed: ${error}`;
                    return;
                }
                if (success) {
                    this.$emit("key:refreshed", this.customer);
                    this.message = `Key Deleted`;
                }
            } catch (e) {
                this.message = `Deletion Failed: ${e}`;
            }
        },
        /**
         * Expires any active key of same type then creates new key if not deployed
         *
         * @param {String} keyType
         */
        async createKey (keyType) {
            try {
                const params = {
                    type: keyType,
                    customerId: this.customer,
                };

                const { success, error } = (
                    await this.axios.post(
                        "/devopsapi/customers/createKey",
                        params
                    )
                ).data;
                if (error) {
                    this.message = error;
                    return;
                }
                if (success) {
                    this.$emit("key:refreshed", this.customer);
                    this.message = "Key Created";
                }
            } catch (e) {
                console.error("createKey Error", e);
            }
        },
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== "";
            },
            set (val) {
                if (val === false) {
                    this.message = "";
                }
            },
        },
    },
    filters: {
        niceDateFromUtc: function (value) {
            if (!value) return "";
            return DateTime.fromISO(String(value))
                .setZone("America/New_York")
                .toLocaleString(DateTime.DATETIME_MED);
        },
    },
    mounted () {
        window.keystablevm = this;
    },
};
</script>
